
















import {Component, Prop, Vue} from 'vue-property-decorator';
import {ICalendarEvent} from "@/store/calendarEvent";

@Component({
  components: {},
})

export default class HeaderBar extends Vue {
  @Prop({default:[]}) images: Array<string> | undefined;

  currentIndex:number = 0;


  setCurrentIndex(index:number) {
    this.currentIndex = index;
  }



    get sliderContainerWidth(): any {
      if(this.images)
        return (this.images.length * 100) + '%';
    }


}

