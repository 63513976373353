









































import {Component, Vue} from 'vue-property-decorator';
import {Action} from "vuex-class";
import {actionStringWork, IWork} from "@/store/work";
import HeaderBar from "@/components/HeaderBar.vue";
import Loader from "@/components/loader.vue";
import {actionStringCalendarEvent, ICalendarEvent} from "@/store/calendarEvent";
import {formatDate} from "@/Types/formatDateHelper";

@Component({
  components: {Loader, HeaderBar},
})

export default class Article extends Vue {
  @Action(actionStringCalendarEvent.GET_CALENDAR_EVENTS) getCalendarEvents: (() => Promise<ICalendarEvent[]>) | undefined;

  calendarEvents:ICalendarEvent[] | null = null;

  oddOrEven(num:number):string{
    return num & 1 ? "odd" : "even"
  }

  async created(): Promise<void> {
    if(this.getCalendarEvents) {
      const tempCalendar = await this.getCalendarEvents();
        this.calendarEvents = tempCalendar.reverse();
    }

  }
}

