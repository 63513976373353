








































import {Component, Vue} from 'vue-property-decorator';
import HeaderBar from "@/components/HeaderBar.vue";
import {Action} from "vuex-class";
import {actionStringWork, Category, IWork} from "@/store/work";
import Loader from "@/components/loader.vue";
import FooterMain from "@/components/FooterMain.vue";
import FooterBar from "@/components/FooterBar.vue";

interface IArticle{
  imageUrl:string,
  title:string,
}

@Component({
  components: {
    FooterBar,
    FooterMain,
    Loader,
    HeaderBar
  },
})

export default class Home extends Vue {
  workList:IWork[] = [];
  projectLinks:Array<string> = ["How to die", "Stranger within", "How"];
  projectLinksURL:Array<string> = [
      "/article/How%20to%20die%20-%20Inopiné",
      "/article/Stranger%20Within%20Hedmark",
      "/article/HOW"];
  currentProjectLink:string = this.projectLinks[0];
  currentProjectLinkURL:string = this.projectLinksURL[0];

  backgroundImages:Array<string> = ["mia-habib.jpeg", "mia-4.jpg", "https://firebasestorage.googleapis.com/v0/b/mia-habib-f9b6c.appspot.com/o/mihabibproductions.jpg?alt=media&token=8e5989d9-c3a6-43b5-b206-8911427fef0c"];
  backgroundImageIndex:number = 0;
  loading:boolean = false;

  @Action(actionStringWork.GET_LATEST_WORK) getLatestWork: (() => Promise<IWork[]>) | undefined;


  nextImage():void{
    setInterval(() => {
      if(this.backgroundImageIndex === this.backgroundImages.length-1){
        this.backgroundImageIndex = 0;
        this.currentProjectLink = this.projectLinks[this.backgroundImageIndex];
        this.currentProjectLinkURL = this.projectLinksURL[this.backgroundImageIndex];

      }else {
      this.backgroundImageIndex = this.backgroundImageIndex +1;
      this.currentProjectLink = this.projectLinks[this.backgroundImageIndex]
      this.currentProjectLinkURL = this.projectLinksURL[this.backgroundImageIndex];

      }
    }, 5000);
  }
  navigateProject():void{
    this.$router.push(this.currentProjectLinkURL);
  }

  prevImage():void{
    if(this.backgroundImageIndex === 0){
      this.backgroundImageIndex = this.backgroundImages.length -1;
    } else{
      this.backgroundImageIndex = this.backgroundImageIndex -1;
    }
  }

  navigate(route:string):void{
    this.$router.push(route);
  }

  navigateArticle(route:string, category:Category):void{
    if(category === Category.films){
      return;
    }

    // let prettyRoute = route.replace(/ /g,"-");
    this.$router.push('article/' + route);
  }


  created():void{
    if(this.getLatestWork){
      this.loading = true;
      this.getLatestWork().then(res => {

        console.log("res after", res);

        this.workList = res;

        this.loading = false;
      }).catch(err => {
        console.log(err);
        this.loading = false;
      })
    }

    this.nextImage();
  }

}
