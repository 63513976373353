





















import {Component, Vue} from 'vue-property-decorator';

@Component({
  components: {},
})

export default class facebookIcon extends Vue {
  created(): void {

  }
}

