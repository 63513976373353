











































import {Component, Vue, Watch} from 'vue-property-decorator';
import HeaderBar from "@/components/HeaderBar.vue";
import {actionStringWork, Category, IWork} from "@/store/work";
import {Action} from "vuex-class";
import {workCategory} from "@/Types/Types";
import Loader from "@/components/loader.vue";
import FooterBar from "@/components/FooterBar.vue";
import firebase from "firebase";


@Component({
        components: {FooterBar, Loader, HeaderBar},
    })

    export default class Work extends Vue {
        // @Action(actionStringWork.GET_WORK) getWork: (() => Promise<IWork[]>) | undefined;
        @Action(actionStringWork.GET_SORTED_WORK) getSortedWork: (() => Promise<IWork[]>) | undefined;


        work:IWork[] = [];
        selectedCategory:Category = Category.all;
        localCategory:Category[] = workCategory;
        selectedCategoryFilter:Category = Category.blank;
        loading:boolean = false;


        setCategory(category:Category):void{
            this.selectedCategory = category;
            this.selectedCategoryFilter = category;
        }

        navigate(route:string, category:Category):void{
          if(category === Category.films){
            return;
          }



            // let prettyRoute = route.replace(/ /g,"-");
            this.$router.push('article/' + route);
        }

        get selectedFilter():string{
            if(this.selectedCategory === Category.all){
                this.selectedCategoryFilter = Category.blank
            } else{
                this.selectedCategoryFilter = this.selectedCategory;
            }

            return this.selectedCategoryFilter;
        }





      @Watch('$route')
      runThis(old:any, tes:any):void{
          // if(this.$route.query.filter){
            this.setCategory(this.$route.query.filter as Category)
            console.log("RAAAAAN")
      }


      updated():void{
          console.log("UPDATED");
        console.log("ROUTE", this.$route.query.filter)


      }

        created(): void {

            if(this.getSortedWork){
                this.loading = true;
                this.getSortedWork().then(res => {
                    console.log("work res", res);



                    const workWithoutPreviewArticles = res.filter(work =>  !work.markedForPreview);

                    // const sorted = workWithoutPreviewArticles.slice().sort((a, b) => {
                    //     const priorityA = a.priority || 0; // Use 0 as the default value if 'priority' is undefined
                    //     const priorityB = b.priority || 0;
                    //
                    //     return priorityB - priorityA; // Sort in descending order
                    // });






                  firebase.auth().onAuthStateChanged((user) => {
                    if (user) {
                      this.work = res;
                    } else {
                      this.work = workWithoutPreviewArticles;
                    }

                  });




                    this.loading = false;
                    if(this.$route.query.filter){
                      this.setCategory(this.$route.query.filter as Category)
                    }
                }).catch(err => {
                    console.log(err);
                    this.loading = false;
                })
            }




        }
    }

