







import {Component, Vue} from 'vue-property-decorator';
import HeaderBar from "@/components/HeaderBar.vue";
import FooterMain from "@/components/FooterMain.vue";
import FooterBar from "@/components/FooterBar.vue";

@Component({
  components: {FooterBar, FooterMain, HeaderBar},
})

export default class Contact extends Vue {
  created(): void {

  }
}

