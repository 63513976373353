




















import {Component, Vue} from 'vue-property-decorator';

@Component({
    components: {},
})

export default class CloseIcon extends Vue {
    created(): void {

    }
}

