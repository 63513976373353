






















import {Component, Vue} from 'vue-property-decorator';
import HeaderBar from "@/components/HeaderBar.vue";
import Loader from "@/components/loader.vue";
import FooterBar from "@/components/FooterBar.vue";
import FooterMain from "@/components/FooterMain.vue";


@Component({
  components: {FooterMain, FooterBar, Loader, HeaderBar},
})

export default class Presenters extends Vue {

  password:string = "randomAccess";
  input:string = "";
  hasAccess:boolean = false;

  access():void{
    if(this.input === this.password){
      this.hasAccess = true;
    }
  }

  created(): void {

  }
}

