





































import {Component, Vue} from 'vue-property-decorator';
import {Action, Getter, Mutation} from "vuex-class";
import {getterStringMenuModal, mutationStringMenuModal} from "@/store/menuModal";
import CloseIcon from "@/components/CloseIcon.vue";
import HeaderBar from "@/components/HeaderBar.vue";
import {actionStringWork, Category, IWork} from "@/store/work";
import {category, pressCategory, workCategory} from "@/Types/Types";
import Loader from "@/components/loader.vue";

@Component({
  components: {Loader, HeaderBar},
})



export default class Press extends Vue {
  localCategory:Category[] = pressCategory;
  selectedCategoryFilter:Category = Category.blank;
  loading:boolean = false;
  selectedCategory:Category = Category.media;
  work:IWork[] = [];

  @Action(actionStringWork.GET_WORK) getWork: (() => Promise<IWork[]>) | undefined;



  setCategory(category:Category):void{
    this.selectedCategory = category;
    this.selectedCategoryFilter = category;
  }

  navigate(route:string):void{
    let prettyRoute = route.replace(/ /g,"-");
    this.$router.push('article/' + prettyRoute);
  }

  get selectedFilter():string{
    if(this.selectedCategory === Category.media){
      this.selectedCategoryFilter = Category.media
    } else{
      this.selectedCategoryFilter = this.selectedCategory;
    }

    return this.selectedCategoryFilter;
  }

  created(): void {
    if(this.getWork){
      this.loading = true;
      this.getWork().then(res => {
        console.log(res);
        this.work = res;
        this.loading = false;
      }).catch(err => {
        console.log(err);
        this.loading = false;
      })
    }
  }


}

