
























import {Component, Vue} from 'vue-property-decorator';

@Component({
  components: {},
})

export default class instagramIcon extends Vue {
  created(): void {

  }
}

