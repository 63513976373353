



















































import {Component, Vue} from 'vue-property-decorator';
import FacebookIcon from "@/components/facebookIcon.vue";
import InstagramIcon from "@/components/instagramIcon.vue";

@Component({
    components: {InstagramIcon, FacebookIcon},
})

export default class FooterMain extends Vue {
    created(): void {

    }
  contact():void{
      this.$router.push("/contact");
  }
}

