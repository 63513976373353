



































import {Component, Vue} from 'vue-property-decorator';
import {Action} from "vuex-class";
import {actionStringWork, Category, IWork} from "@/store/work";
import HeaderBar from "@/components/HeaderBar.vue";
import Loader from "@/components/loader.vue";
import GallerySlider from "@/components/GallerySlider.vue";
import {actionStringImageGallery, IimageGallery} from "@/store/imageGallery";

@Component({
    components: {GallerySlider, Loader, HeaderBar},
})

export default class Article extends Vue {
    @Action(actionStringWork.GET_WORK_BY_ID) getWorkById: ((workId: string) => Promise<IWork>) | undefined;
    @Action(actionStringImageGallery.GET_GALLERY_BY_ID) getGalleryById: ((galleryId: string) => Promise<IimageGallery>) | undefined;



    images:Array<string> | null = null
    loading:boolean = false;
    $route: any;
    article:IWork | null = null;
    relatedArticles:any = [];


    navigate(route:string, category:Category):void{
        if(category === Category.films){
            return;
        }

        console.log("route", route)

        this.$router.push('/article/' + route);
        window.location.reload();
    }

    async  fetchDataForIds(Ids:Array<string>) {
        const responseArray = [];

        for (const id of Ids) {
            console.log("IDS", id)

            try {
                // const response = await fetch(`https://api.example.com/data/${id}`);
                if(this.getWorkById){
                    const res = await this.getWorkById(id)
                    console.log("RES", res)
                    responseArray.push(res);
                }
            } catch (error) {
                console.error(`Error fetching data for ID ${id}:`, error);
            }
        }

        return responseArray;
    }


    async created(): Promise<void> {
        this.loading = true;
      console.log("YAKL")
        let articleId = this.$route.params.id;


        if(this.getWorkById){
            this.article = await this.getWorkById(articleId);
        }

        console.log("article..", this.article)

        if(this.article?.relatedArticles.length > 0){
            this.relatedArticles = await this.fetchDataForIds(this.article?.relatedArticles)
        }

        if(this.getGalleryById){
            console.log("ARTICLE?", this.article)
            let imageGallery = await this.getGalleryById(this.article?.imageGallerySlider as string)
            console.log(imageGallery)
                this.images = imageGallery.images as Array<string>;
            console.log("images", this.images)
        } else {
            this.images = null;
        }



        this.loading = false;

        console.log("runnnning?")



        console.log("the fuck res", this.relatedArticles)

        if(!this.loading){
            const qlSyntaxElement = document.querySelector(".ql-syntax");
            const imageSliderElement = this.$el.querySelector(".image-slider");

            console.log("QL", qlSyntaxElement)
            console.log("IMS", imageSliderElement)


            if (qlSyntaxElement && imageSliderElement) {
                qlSyntaxElement.appendChild(imageSliderElement);
            }
        }


    }



}

