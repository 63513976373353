











































import {Component, Vue} from 'vue-property-decorator';
import {Getter, Mutation} from "vuex-class";
import {getterStringMenuModal, mutationStringMenuModal} from "@/store/menuModal";
import CloseIcon from "@/components/CloseIcon.vue";

@Component({
    components: {CloseIcon},
})



export default class MenuModal extends Vue {
    @Getter(getterStringMenuModal.IS_SHOWING_MENU_MODAL) isShowingMenuModal: boolean | undefined;
    @Mutation(mutationStringMenuModal.SET_IS_SHOWING_MENU_MODAL) setIsShowingMenuModal: ((payload: boolean) => void) | undefined;


    hideMenu():void{
        console.log("HIDE");

        if(this.setIsShowingMenuModal)
        this.setIsShowingMenuModal(false);
    }

    navigate(route:string):void{
      this.$router.push("/" + route);
      this.hideMenu();
    }

    created(): void {

        console.log("?", this.isShowingMenuModal);

    }


}

