



































import {Component, Vue} from 'vue-property-decorator';
import FacebookIcon from "@/components/facebookIcon.vue";
import InstagramIcon from "@/components/instagramIcon.vue";

@Component({
  components: {InstagramIcon, FacebookIcon},
})

export default class FooterBar extends Vue {
    currentYear:any = null;

  created(): void {
    this.currentYear = new Date().getFullYear();
  }
  contact():void{
    this.$router.push("/contact");
  }
}

