







import {Component, Vue} from 'vue-property-decorator';

@Component({
    components: {},
})

export default class loader extends Vue {

    created(): void {

    }
}

