



















import {Component, Prop, Vue} from 'vue-property-decorator';
import {Getter, Mutation} from "vuex-class";
import {getterStringMenuModal, mutationStringMenuModal} from "@/store/menuModal";
import HamburgerMenu from "@/components/HamburgerMenu.vue";

@Component({
    components: {
      HamburgerMenu

    },
})

export default class HeaderBar extends Vue {
    @Prop({default:false}) hasBackground: boolean | undefined;
    @Mutation(mutationStringMenuModal.SET_IS_SHOWING_MENU_MODAL) setIsShowingMenuModal: ((payload: boolean) => void) | undefined;
    @Getter(getterStringMenuModal.IS_SHOWING_MENU_MODAL) isShowingMenuModal: boolean | undefined;




    openMenu():void{

        if(this.setIsShowingMenuModal){
            this.setIsShowingMenuModal(true);
        }
    }

  navigate(path:string):void{
    this.$router.push(path);
  }

    created():void{

    }
}

